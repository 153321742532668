.nav-container{
    width:100vw;
    height:60px;
    display: flex;
    align-items: center;
    padding:0px 16px;
    background:#0E1227;
}

.nav-container .logo{
    display: flex;
    color:white;
}

.nav-container .cortex-text{
    margin-left:12px;
    font-weight: 600;
    font-size:20px;
}

.nav-content{
    width:100%;
    display: flex;
    justify-content: space-between;
}

.nav-links{
    display: flex;
    align-items: center;
    margin-right: 18px;
}

.nav-links .link{
    margin-left:24px;
    color:white;
    font-size:14px;
    cursor: pointer;
    text-decoration: none;
}


.nav-left-content{
    display: flex;
    align-items: center;
}

.nav-links .active-link{
    text-decoration: underline;
}