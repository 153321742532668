.search-container {
    margin-left: 140px;
    background-color: rgba(10, 139, 254, 0.1);
    padding: 4px 20px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    width: 200px;  
    position:relative;
}

.search-container .input{
    display: flex;
    justify-content: space-between;
    color:white;
    align-items: center;
    font-size:14px;
    width:100%;
    cursor: caret;
}

.input div{
    display: flex;
    align-items: center;
}

.ctrl-icon{
    font-size:12px;
    display: flex;
    align-items: center;
}

.search-container input::placeholder {
    color: white;
    font-size:14px;
    margin-bottom:4px;
}



